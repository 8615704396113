import { ICompany } from "@icebreaker/types"
import Link from 'next/link'
import styled from 'styled-components'

import FlagIcon from '../flag-icon'
import { scoreLabel } from "@utils";

interface RowProps {
    published?: string;
    header?: string;
}

interface ColumnProps {
    width?: number;
    first?: boolean;
}


const MainRow = styled(Link) <RowProps>`
display: grid;
grid-template-columns: 35% 65%;
grid-template-rows: 100% auto;
border-bottom: solid 1px #e9ecf5;

color:#505070;
padding: 0.8em 0.7em;
border-left: ${props => props.published ? 'none' : '5px solid black'};

&:first-of-type {
    cursor:default;
    background:#eaecf3;
    font-weight:bold;
    border:none;
    color:#000;
}

&:hover {
    ${props => props.header ? '' : 'background:#e9ecf5;'}
    }
`

const Row = styled.div<RowProps>`
display: grid;
grid-template-columns: repeat(auto-fill, 33.333%);
grid-template-rows: 100% auto;
`

const Column = styled.div<ColumnProps>`
box-sizing: border-box;
flex-grow: 1;
overflow: hidden; // Or flex might break
list-style: none;
width: 100%;  // Default to full width
padding:0 .3em;
text-align:${props => props.first ? 'left' : 'center'};
font-weight:${props => props.first ? '700' : 'inherit'};
`

const NameContainer = styled.div`
align-items: center;
display:flex;
display: grid;
grid-template-columns: 1.4em auto;
grid-template-rows: 100% auto;
`
const Name = styled.div`
overflow:hidden;
text-overflow:ellipsis;
white-space:nowrap;
color:#000;
`

const Flag = styled.div`
align-items: center;
display:flex;
padding-right:.34em;
`

function formatDate(date: Date) {
    date = new Date(date)

    const yearsAgo = new Date().getFullYear() - date.getFullYear();
    let yearDiffInMonths = 0;

    if (yearsAgo > 0) {
        yearDiffInMonths = yearsAgo * 12;
    }

    const monthDiff = new Date().getMonth() - date.getMonth();
    const age = yearDiffInMonths + monthDiff;

    return age === 1 ? `1 month` : `${age} months`;
}

export default function CompanyListRow({ company, headers }: { company?: ICompany, headers?: string[] }) {
    if (headers) {
        return <MainRow header={"true"} href="#">
            <Column first={true}>{headers[0]}</Column>
            <Row>
                {headers.map((header, index) => {
                    if (index === 0) return null;
                    return <Column key={index}>
                        {header}
                    </Column>
                })}
            </Row>
        </MainRow>
    }
    else if (company) {
        return <>
            <MainRow href={`/companies/${company._id}`} published={company.published ? "true" : undefined}>

                <Column>
                    <Column first={true} width={200}>
                        <NameContainer>
                            <Flag>
                                <FlagIcon country={company.country} />
                            </Flag>
                            <Name>
                                {company.name}
                            </Name>
                        </NameContainer>
                    </Column>
                </Column>
                <Column>
                    <Row>
                        <Column>{scoreLabel(company)}</Column>
                        <Column>{company.registrationDate && formatDate(company.registrationDate)}</Column>
                        <Column>
                            {company.dataSource}
                        </Column>
                    </Row>
                </Column>
            </MainRow>
        </>
    }
    else {
        return null;
    }
}