
export default {
    DATA_PROCESSING_STATUS: {
        NEW: 'new', // Newly fetched
        READY_TO_PUBLISH: 'ready-to-publish',
        DISQUALIFIED: 'disqualified', // Consultancies
        OPEN: 'open', // Open for more data either by bot or human
        CLOSED: 'closed' // Closed. Used for processed companies when migrating from old system
    },

    APPROVE_REASON: {
        MAYBE_INTERESTING: 2,
        MAYBE_INTERESTING_SAVE_FOR_LATER: 9,
        DEFINITELY_INTERESTING: 3,
        SUPER_INTERESTING: 4
    },

    REJECTION_REASON: {
        NO_DATA: 0,
        NOT_INTERESTING: 1,
        DONT_KNOW: 99
    },

    COUNTRIES: ['SE', 'FI', 'EE'],
    COUNTRY: {
        SWEDEN: 'SE',
        FINLAND: 'FI',
        ESTONIA: 'EE'
    },

    TTL: {
        ONE_YEAR: 31536000,
        SIX_MONTHS: 15778463,
        ONE_MONTH: 2592000,
        ONE_WEEK: 604800,
        ONE_DAY: 86400,
    },

    TOP_TLDS: ["ai", "io", "app"],

    COMPANY_TITLES: {
        DeputyBoardMember: 'Deputy Board Member',
        Accountant: 'Accountant',
        LeadAccountant: 'Lead Accountant',
        Liquidator: 'Liquidator',
        ExternalAuthorizedSignatory: 'External Authorized Signatory'
    },

    SCORE_BOOST: {
        SALES_NAVIGATOR: 1000,
    }
};