import constants from '@constants';
import { ICompany, IPerson } from "@icebreaker/types";


function getLinkedInGeoId(country: string | undefined | null): string {
    switch (country) {
        case constants.COUNTRY.SWEDEN:
            return '105117694'
        case constants.COUNTRY.FINLAND:
            return '100456013'
        case constants.COUNTRY.ESTONIA:
            return '102974008'
        default:
            return "";
    }
}

export default function getLinkedInUrl({ directLink, person, company }: { directLink: string | null | undefined, person?: IPerson, company?: ICompany }): string {

    if (directLink) {
        return directLink;
    }

    if (person) {
        const geoId = getLinkedInGeoId(company?.country);
        const query = person.displayName || person.scrapedName;
        return `https://www.linkedin.com/search/results/people/?geoUrn=[${geoId}]&keywords=${encodeURIComponent(query || "")}`;
    }

    if (company) {
        return `https://www.linkedin.com/search/results/companies/?keywords=${encodeURIComponent(company.name || "")}`;
    }

    return "";
}