import styled from 'styled-components';
// See more flag svg's here: https://github.com/lipis/flag-icons/tree/main/flags/4x3

const Img = styled.img`
border:1px solid #dbdbdb;
`

export default function FlagIcon({ country }: { country: string | undefined | null }) {
    if (!country) return <span>🏴</span>;

    return <Img src={`/flags/${country.toLowerCase()}.svg`} />
}