import { DataSource } from "./";

export enum IFiltersSortBy {
    CREATEDAT_ASC = "CREATEDAT_ASC",
    CREATEDAT_DESC = "CREATEDAT_DESC",
    PROCESSEDAT_ASC = "PROCESSEDAT_ASC",
    PROCESSEDAT_DESC = "PROCESSEDAT_DESC",
    PUBLISHDATE_ASC = "PUBLISHDATE_ASC",
    PUBLISHDATE_DESC = "PUBLISHDATE_DESC",
    SCORE_ASC = "SCORE_ASC",
    SCORE_DESC = "SCORE_DESC",
}

export interface IFilters {
    dataSources?: DataSource[] | null;
    processed?: boolean;
    rejected?: boolean;
    published?: boolean;
    sortBy: IFiltersSortBy;
}
