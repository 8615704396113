import { useSession } from "next-auth/react";
import Multiselect from 'multiselect-react-dropdown';
import { DataSource, IFilters, IFiltersSortBy } from "@icebreaker/types"
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from 'react-select'
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e3e5ec;
    padding: 0.7em;
    border-bottom: 1px solid #d9dae8;

    .form-check{
        margin-right: 1em;
    }
    `

const selectStyles = {
    option: (styles: any) => ({
        ...styles,
        cursor: 'pointer',
    }),
    control: (styles: any) => ({
        ...styles,
        cursor: 'pointer',
        minHeight: '41px',
        width: '200px'
    }),
    placeholder: (styles: any) => ({
        ...styles,
        color: '#283146',
    })
}

export default function Filters({ onChange }: { onChange: (filters: IFilters) => void }) {
    const { data: session } = useSession()

    const [filter, setFilter] = useState<IFilters>({
        dataSources: null,
        sortBy: IFiltersSortBy.SCORE_DESC,
        processed: false,
        rejected: false,
        published: true,
    })

    const { register, handleSubmit, reset, formState, setFocus } = useForm<IFilters>({
        defaultValues: filter
    });

    useEffect(() => {
        if (filter) {
            onChange(filter)
        }
    }, [filter])

    function onDataSourceSelect(selectedList: any, selectedItem: any) {
        setFilter({
            ...filter,
            dataSources: selectedList.length ? selectedList : null
        })
    }

    function onSortChange({ value, label }: any) {

        setFilter({
            ...filter,
            sortBy: value
        })
    }

    const dataSourceOptions = Object.values(DataSource).map(source => {
        return source;
    })

    const sortOptions = Object.values(IFiltersSortBy).map(source => {
        return {
            value: source, label: source
        };
    })

    function onCheckboxChange(e: any) {
        setFilter({
            ...filter,
            [e.target.id]: e.target.checked
        })
    }

    return <Container>

        <div className="flex">
            <Multiselect
                isObject={false}
                options={dataSourceOptions} // Options to display in the dropdown
                onSelect={onDataSourceSelect} // Function will trigger on select event
                onRemove={onDataSourceSelect} // Function will trigger on remove event
                displayValue="dataSource"
                placeholder="Data source"
                style={{ multiselectContainer: { width: '250px', margin: '0 1em 0 0' } }}
            />

            <div className="form-check">
                <input type="checkbox"
                    className="form-check-input"
                    id={"processed"}
                    {...register("processed")}
                    onChange={onCheckboxChange}
                />
                <label htmlFor={"processed"}>Processed</label>
            </div>

            <div className="form-check">
                <input type="checkbox"
                    className="form-check-input"
                    id={"published"}
                    {...register("published")}
                    onChange={onCheckboxChange}
                />
                <label htmlFor={"published"}>Published</label>
            </div>

        </div>

        <Select
            options={sortOptions}
            onChange={onSortChange}
            placeholder="Sort by"
            styles={selectStyles}
        />

    </Container>
}