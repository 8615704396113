import { ICompany } from "./company";

export * from './company'
export * from './crunchbreaker-company'
export * from './db'
export * from './duplicate-data-note'
export * from './event-log-id'
export * from './events'
export * from './filters'
export * from './job'
export * from './linkedin'
export * from './nubela'
export * from './pipedrive/pipedrive'
export * from './phantombuster'
export * from './phantombuster-response'
export * from './person'
export * from './reprocess-filter'
export * from './score'
export * from './scavenger'
export * from './source-api'
export * from './update-company-from-ui'
export * from './update-person-from-ui'
export * from './user-analytics'
export * from './work-experience'

export interface CompanyBuckets {
    companiesToInsert: ICompany[];
    companiesOpenForUpdates: CompanyToUpdate[];
    companiesToIgnore: CompanyToIgnore[];
}

export interface CompanyToUpdate {
    fetchedCompany: ICompany;
    dbCompany: ICompany;
}

export interface CompanyToIgnore {
    fetchedCompany: ICompany;
    dbCompany: ICompany;
}

// READ BELOW if you're adding a new data source:
// If it's a startup directory or similar, you *might* want to add the source to the source array in file `check-for-duplicate-entities/index.ts`. 
// That job will check for duplicate companies in the database. 
export enum DataSource {
    Antler = "antler",
    Allabolag = "allabolag",
    Dsystems = "d-systems",
    LinkedInSalesNavigator = "linkedin-sales-navigator",
    SlushMatchmaking = "slush-matchmaking",
    StartupEstonia = "startup-estonia",
    Sting = "sting",
    TheHub = "thehub",
    Tracxn = "tracxn",
    Prh = "prh"
}
