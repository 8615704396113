const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

// Format: "Mar 2020"
function MMMYYYY(date: Date | null | undefined): string {
    if (!date) return "";
    date = new Date(date);

    const month = months[date.getMonth()];
    const day = date.getDate();

    // Fallback: If Jan 1st, show only the year
    // Why? Sometimes we have only years and not months and days. In that case, we want to show only the year.
    if (day === 1 && month === "Jan") {
        return date.getFullYear().toString();
    }

    return `${month} ${date.getFullYear()}`;
}


export default {
    MMMYYYY,
}