import constants from '@constants'
import { DataSource, ICompany } from "@icebreaker/types";

export function scoreLabel(company: ICompany) {

    if (company.dataSource === DataSource.LinkedInSalesNavigator) {
        return (company.score || 0) - constants.SCORE_BOOST.SALES_NAVIGATOR;
    }

    return company.score;

}