import { ICompany, IFilters } from "@icebreaker/types";
import axios from "axios";
import { useState } from "react";
import Skeleton from 'react-loading-skeleton'

import Row from './company-list-row'
import Filters from './Filters'

export default function CompanyList() {

    const [data, setData] = useState<any>(null)
    const [error, setError] = useState<any>(null)
    const [loading, setLoading] = useState<any>(true)

    const getCompanies = async (filters: IFilters) => {
        setLoading(true)

        try {
            // TEMP-DEMO-CHANGE: from /api2 to /api
            // CHANGE IT BACK BEFORE PRODUCTION RELEASE
            const { data } = await axios.post("/api/companies/list", {
                filters
            })

            if (data) {
                setData(data)
            }
        }
        catch (e: any) {
            setError(e)
        }
        setLoading(false)
    }

    if (error) {
        console.log(error);
        return <div>Error.. See console</div>
    }

    return <>
        <Filters
            onChange={(filters) => {
                getCompanies(filters)
            }}
        />
        <Row headers={['Name', 'Score', 'Age', 'Source']} />
        {loading ?
            <>
                <Skeleton count={20} height={40} />
            </>
            : <>
                {data &&
                    <>
                        {!data.companies.length && <div style={{ padding: '2em' }} className='text-center'>No companies left! 🥳</div>}

                        {data.companies.map((company: ICompany) => {
                            return <Row company={company} key={company._id} />
                        })}

                    </>}
            </>}
    </>

}