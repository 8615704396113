function start() {
    return process.hrtime();
}

function stop(timer: any) {
    const elapsed = process.hrtime(timer)[1] / 1000000;
    timer = process.hrtime(); // reset the timer
    return elapsed.toFixed(0);
}

export default {
    start,
    stop
}