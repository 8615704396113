export function getLinksForHumans(links: string[] | undefined): string[] {

    if (!links) return [];

    return links?.filter(link => {
        if (link) {
            let isForHuman = true;

            if (link.includes('thehub.io/api') || link.includes("avoindata.prh.fi/opendata")) {
                isForHuman = false;
            }

            return isForHuman;
        }
    });

}