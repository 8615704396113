import axios from 'axios';
import moment from 'moment';
import { CompaniesToProcessLocalStorageId } from '../../types/global';

interface Params {
    forceRefresh?: boolean;
}

export default async function getCompaniesToProcess(currentUserId: string | undefined, { forceRefresh }: Params = {}) {

    if (!currentUserId) {
        throw new Error("No currentUserId provided");
    }
    const localStorageIdCompaniesToReprocess: CompaniesToProcessLocalStorageId = "companiesToReprocess";

    // Prioritize companies that need to be reprocessed
    const companiesToReprocess = JSON.parse(localStorage.getItem(localStorageIdCompaniesToReprocess) || "[]");

    if (companiesToReprocess.length) {
        return companiesToReprocess;
    }

    const localStorageIdClaimedCompanies: CompaniesToProcessLocalStorageId = "claimedCompanies";

    const claimedCompaniesUpdatedAt = localStorage.getItem("claimedCompaniesUpdatedAt") ? moment(localStorage.getItem("claimedCompaniesUpdatedAt")) : moment();
    const claimedCompanies = JSON.parse(localStorage.getItem(localStorageIdClaimedCompanies) || "[]");

    const diffHours = moment().diff(claimedCompaniesUpdatedAt, 'hours');

    // If user has claimedCompanies that are somewhat newly updated, return them
    if (claimedCompanies.length > 1 && diffHours < 4 && !forceRefresh) {
        return claimedCompanies;
    }

    let lastCompanyId;

    if (claimedCompanies.length === 1) {
        lastCompanyId = claimedCompanies[0];
    }

    // Fetch new companies from API
    // Send the currently claimed companies to the API
    const response: any = await axios.post('/api/companies/processable', { currentUserId, lastCompanyId });

    if (response.status === 200) {

        const { companies } = await response.data;

        // Store companies in localStorage for blazing fast access
        localStorage.setItem(localStorageIdClaimedCompanies, JSON.stringify(companies));
        localStorage.setItem("claimedCompaniesUpdatedAt", moment().toISOString());

        return companies;

    }
    else {
        return []
    }

}