import constants from '@constants'
import { ICompany } from "@icebreaker/types";

export default function formatLocation(company: ICompany) {

    let country = company.country;

    switch (company.country) {
        case constants.COUNTRY.SWEDEN:
            country = "Sweden";
            break;
        case constants.COUNTRY.FINLAND:
            country = "Finland";
            break;
        case constants.COUNTRY.ESTONIA:
            country = "Estonia";
            break;
    }

    return company.city ? `${company.city}, ${country}` : country;

}